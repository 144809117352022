import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import { Wrapper } from "../Grid";

const AboutWrapper = styled(Wrapper)`
  position: relative;
  background-color: ${themeGet("colors.darkGreen")};
  overflow: hidden;
  .bg {
    z-index: 5;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-position: 100% -100px;
    background-image: url(${require("../../assets/about-bg-mobile.jpg")});
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (min-width: ${themeGet("breakpoints.sm")}) {
      background-position: bottom center;
      width: 1900px;
      background-image: url(${require("../../assets/bg-about.jpg")});
    }
  }
`;

export { AboutWrapper };
