import React, { Fragment } from "react";
import { graphql, Link } from "gatsby";

import { Box, Container, Wrapper } from "../style/Grid";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";
import { Image } from "../style/Image";
import { AboutWrapper } from "../style/About";
import { Wysiwyg } from "../style/Wysiwyg";
import { Button } from "../style/Button";
import HeaderInner from "../components/HeaderInner";
import Meta from "../components/Meta";
import { parseContent } from "../utils/lang";

export default ({
  data,
  mobileMenuOpen,
  toggleMobileMenu,
  pageContext: { translations, links, lang, currentSlug },
}) => {
  const { frontmatter, html } = parseContent(data.markdownRemark, lang);

  return (
    <Fragment>
      <Meta
        title={frontmatter.meta.title}
        description={frontmatter.meta.description}
      />
      <AboutWrapper p={0}>
        <Wrapper position="relative" zIndex={1000}>
          <HeaderInner
            mobileMenuOpen={mobileMenuOpen}
            toggleMobileMenu={toggleMobileMenu}
            translations={translations}
            links={links}
            lang={lang}
            slug={currentSlug}
          />
        </Wrapper>

        <div className="bg" />
        <Container p={[5, null, null, 100]} position="relative" zIndex={50}>
          <Heading as="h1" fontSize="h1" mb={[5, null, null, 80]} color="white">
            {frontmatter.title}
          </Heading>

          <Box maxWidth={800}>
            <Wysiwyg dangerouslySetInnerHTML={{ __html: html }} color="white" />
          </Box>
        </Container>
      </AboutWrapper>

      <Wrapper py={[4, null, null, 5]}>
        <Container
          flexWrap="wrap"
          flexDirection="row"
          justifyContent="space-between"
        >
          {frontmatter.profiles.map((profile, index) => (
            <Box
              width={[1, null, "calc(50% - 20px)"]}
              key={index}
              mb={[index === 0 && 5, null, 0]}
              id={profile.id}
            >
              <Image
                src={`../../people/${profile.image}`}
                width={1}
                maxWidth={300}
                maxHeight={300}
                display="block"
                mb={4}
                mx={["auto", null, 0]}
                alt={profile.name}
              />
              <Heading as="h2" fontSize="h2">
                {profile.name}
              </Heading>
              <Text color="cyberGreen" mb={3}>
                {profile.title}
              </Text>
              <Wysiwyg dangerouslySetInnerHTML={{ __html: profile.profile }} />
            </Box>
          ))}
        </Container>
      </Wrapper>

      <Wrapper bg="lightTitaniumGrey30" py={5}>
        <Container justifyContent="center">
          <Box textAlign="center">
            <Text fontSize="h4" color="darkGreen" fontWeight="medium" mb={3}>
              {translations.aboutOurServices}
            </Text>
            <Button as={Link} to={links.products.overview}>
              {translations.aboutProducts}
            </Button>
          </Box>
        </Container>
      </Wrapper>
    </Fragment>
  );
};
export const pageQuery = graphql`
  query ABOUT_QUERY($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        slug_cz

        title
        title_cz
        meta {
          title
          description
        }
        meta_cz {
          title
          description
        }

        profiles {
          id
          image
          name
          profile
          title
        }
        profiles_cz {
          id
          image
          name
          profile
          title
        }
      }
    }
  }
`;
